<template>
    <div class="Campervan" :class="{soon: !isSale}">
        <div @click.prevent="openPopup()" class="imageContainer" :class="{emptyImageContainer: isEmpty}">
            <img class="image" :class="{emptyImage: isEmpty}" :src="image" alt="">
            <div v-if="isSale" class="price" :class="{emptyPrice: isEmpty}">{{ price }}</div>
            <div v-if="sold" class="soldTag"> SOLD </div>
        </div>
        <div class="infoContainer">
            <div class="title" :class="{emptyTitle: isEmpty}">{{ title }}</div>
            <div class="year" :class="{emptyYear: isEmpty}">{{ year }}</div>
            <div v-if="isSale" class="conversionLink" :class="{emptyConversionLink: isEmpty}">{{ conversionLink }}</div>
            <div class="info" :class="{ emptyInfo: isEmpty}">
                {{ info }}
            </div>
        </div>
        <div class="buttonsRow">
            <div @click.prevent="!isEmpty? $emit('contact') : false" class="buyButton" :class="{emptyButton: isEmpty}">Buy</div>
            <div v-if="!isEmpty" @click.prevent="$emit('showPopup', {campervanIndex: index, isSale: isSale})" class="readMoreButton">ReadMore</div>
        </div>
    </div>
  </template>
  
  <script>
    import { useSanityFetcher } from 'vue-sanity'
  
  export default {
    name: 'VanGallery',
    
    props: {
        index: {
            type: Number,
        },
        isSale: {
            type: Boolean,
        },
        isEmpty: {
            type: Boolean,
            default: false
        },
        image: {
            type: String,
            default: ''
        },
        price: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        year: {
            type: String,
            default: ''
        },
        conversionLink: {
            type: String,
            default: ''
        },
        info: {
            type: String,
            default: ''
        },
        sold: {
            type: Boolean,
            default: false 
        },
        
    },  
    methods: {
        openPopup(){
            if(!this.isEmpty){
                this.$emit('showPopup', {campervanIndex: this.index, isSale: this.isSale})
            }
        }
    },
    setup() {
        const { data: campervansRaw } = useSanityFetcher(`*[_type == "forSale"]{
            name,
            "frontImage": frontImage.asset->url,
            price,
            yearMiles,
            description,
        }`)
    return {
      campervansRaw
    };
  },
  
    
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .Campervan {
        /* border: 1px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 39em;

        @media (max-width: 620px) {
            /* height: 180vw; */
            height: 42em;
        }

    }
    .soon {
        height: 36em;
    }
    .imageContainer  {
        width: 18em;
        height: 20em;
        position: relative;
        overflow: hidden;
        margin: 0 auto;
        cursor: pointer;

        @media (max-width: 620px) {
            width: 70vw;
            height:89.5vw;
        }
    }
    .image {
        border: 1px solid rgb(255, 255, 255);
        width: 100%; 
        height: 100%; 
        object-fit: cover; 
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .image:hover {
        opacity: 0.7;
    }
    .price {
        position: absolute;
        z-index: 100;
        color: white;
        bottom: 0;
        left: 0;
        padding: 1em;
        font-size: 1.5em;
        background-color: rgba(0, 0, 0, 0.5);

        .empty {
            display: none;
        }
    }
    .title {
        font-size: 1.5em;
        font-weight: 600;
        color: #F15719;
        margin-top: 0.5em;
        text-align: start;

        @media (max-width: 620px) {
            margin-top: 2vw;
        }
    }
    .year {
        font-size: 0.9em;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.782);
        margin-top: 0.2em;
        margin-bottom:  0.8em;
        text-align: start;
    }
    .conversionLink {
        font-size: 1em;
        font-weight: 500;
        color: #29ABE2;
        opacity: 0.6;
        cursor: pointer;
        margin-bottom: 0.8em;
        text-align: start;
    }
    .info {
        font-size: 0.9em;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.782);
        text-align: start;
        max-height: 4em;
        overflow: hidden;
        max-lines: 3;
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        
    }
    .buttonsRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .buyButton {
        color: white;
        background: linear-gradient(to right, #F18C19, #F15819);
        padding: 0.5em 2.4em;
        border-radius: 0.4em;
        margin-top: 2em;
        text-decoration: none;
        cursor: pointer;
    }
    .readMoreButton {
        color: rgb(128, 158, 229);
        padding: 0.5em 2.4em;
        border-radius: 0.4em;
        margin-top: 2em;
        text-decoration: none;
        cursor: pointer;
        opacity: 0.6;
    }
    .readMoreButton:hover {
        opacity: 1;
    }
    .buyButton:hover {
        background: linear-gradient(to right, #faaa4d, #ed8c63);
    }

    .emptyImageContainer {
        background-color: #4a545952;
        width: 18em;
        height: 20.2em;
        opacity: 0.5;
        cursor: default;

        @media (max-width: 620px) {
            width: 70vw;
            height:89.5vw;
        }
    }
    .emptyImage {
        display: none;
    }
    .emptyPrice {
        display: none;
    }
    .emptyTitle {
        height: 1em;
        width: 12em;
        background-color: #4a545952;
        margin-top: 0.6em;
        opacity: 0.3;
        border-radius: 0.2em;

        @media (max-width: 620px) {
            width: 70vw;
            height: 7vw;
            margin-top: 3vw;
        }
    }
    .emptyYear {
        height: 0.9em;
        width: 20em;
        background-color: #4a545952;
        margin-top: 1em;
        opacity: 0.3;
        border-radius: 0.2em;

        @media (max-width: 620px) {
            width: 70vw;
            height: 4vw;
            margin-top: 4vw;
        }
    }
    .emptyConversionLink {
        height: 2em;
        width: 18em;
        background-color: #4a545952;
        margin-top: 1.3em;
        opacity: 0.3;
        border-radius: 0.3em;

        @media (max-width: 620px) {
            width: 70vw;
            height: 8vw;
            margin-top: 6vw;
        }
    }
    .emptyInfo {
        height: 5.5em;
        width: 20em;
        background-color: #4a545952;
        margin-top: 0.3em;
        opacity: 0.3;
        border-radius: 0.4em;

        @media (max-width: 620px) {
            width: 70vw;
            height: 15vw;
            margin-top: 4vw;
        }
    }
    .emptyButton {
        color: transparent;
        background: linear-gradient(to right, #4a545952, #4a545952);
        opacity: 0.4;
        cursor: default;
    }
    .emptyButton:hover {
        background: linear-gradient(to right, #4a545952, #4a545952);
    }

    .soldTag {
        position: absolute;
        top: 1.1em;
        right: -2.4em;
        font-size: 1.5em;
        font-weight: 600;
        transform: rotate(45deg); 
        background-color: #C1272D; 
        color: white; 
        width: 7em;
        padding: 0.5em 1em;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
        z-index: 100; 
    }
  </style>
  