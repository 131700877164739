<template>
    <div class="MainPageImageSlider">
        <div class="flickingContainer">
            <flicking
                :key="3"
                :options="{ circular: true, panelsPerView: -1, autoplay: true}"
                :plugins="plugins"
            >
                <div class="imageCrop"><img class="image" src="../assets/main/1.jpg" alt=""></div>
                <div class="imageCrop"><img class="image" src="../assets/main/2.jpg" alt=""></div>
                <div class="imageCrop"><img class="image" src="../assets/main/3.jpg" alt=""></div>
                <div class="imageCrop"><img class="image" src="../assets/main/4.jpg" alt=""></div>

                <template #viewport>
                    <div class="flicking-pagination"></div>
                </template>
            </flicking>
        </div>
    </div>
  </template>
  
  <script>
  import Flicking from "@egjs/vue3-flicking";
  import "@egjs/vue3-flicking/dist/flicking-inline.css";
  import { Fade, AutoPlay, Pagination } from "@egjs/flicking-plugins";
  import "@egjs/flicking-plugins/dist/pagination.css";

  
  export default {
    name: 'MainPageImageSlider',

    components: {Flicking},
    


    setup() {
      const plugins = [ new Pagination({ type: 'scroll' }), new Fade(), new AutoPlay(2000, "NEXT")];

      return {
        plugins
      }
    },
  
    
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .MainPageImageSlider {
        z-index: 10000;
    }
    .flickingContainer {
        /* display: flex;
        justify-content: center;
        align-items: center; */
        /* width: 20em; */
        /* height: 20em; */
        margin-top: 1em;
    }
    .imageCrop {
        /* display: flex; */
        /* justify-content: center; */
        /* align-items: center; */
        width: 20em;
        height: 20em;
        overflow: hidden;
        border-radius: 2em;

        @media (max-width: 620px) {
            width: 80vw;
            height: 80vw;
        }
    }
    .image {
        height: 100%;
        /* height: auto; */
        /* border: 1px solid red; */
        /* display: inline-flex; */
        /* justify-content: center; */
        /* align-items: center; */
    }
    /* .flicking-pagination {
        border: 1px solid red;
        height: 2em;
        width: 15em;
    } */
  </style>
  