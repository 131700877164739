<template>
  <div class="Contact">
    <div @click.prevent="close" class="bg"></div>
    <div class="popupContainer">
      <img  @click.prevent="close" class="closeButton" src="../assets/closeButton.png" alt="">
      <div class="contactTitle">Contact Us</div>
      <div class="mapInfoContainer">
        <div class="mapContainer">
          <iframe
            class="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d621.2635557967521!2d-2.4889068!3d51.475519!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4871855ae23e3705%3A0x18ce5f24583c1469!2s14%20Bridge%20Rd%2C%20Bristol%20BS15%204PW%2C%20UK!5e0!3m2!1sen!2sgr!4v1696166186019!5m2!1sen!2sgr"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div class="info">
          <div class="address">
            <div class="addressTitle">Address:</div>
            Ideal Campervan, Unit 14C, Bridge Road Industrial Estate, Bridge
            Road, Kingswood, Bristol, BS15 4TA
          </div>
          <div class="mobile">
            <div class="mobileTitle">Mobile:</div>
            07401457697
          </div>
          <div class="mobile">
            <div class="mobileTitle">Landline:</div>
            01174524408
          </div>
          <div class="email">
            <div class="emailTitle">Email:</div>
            idealcampervan@gmail.com
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  components: {},
  setup(props, { emit }) {
    const close = () => {
        emit('close');
    };
    return {
      close
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Contact {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 10000;

  @media (max-width: 620px) {
    
  }
}
.bg {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.762);
}
.popupContainer {
  position: relative;
  padding: 6em 6em;
  padding-top: 3em;
  border-radius: 2em;
  background-color: rgb(14 27 46);
  z-index: 1;

  @media (max-width: 620px) {
    padding: 0 0;
    padding-top: 16vw;
    border-radius: 0;
    height: 100vh;
    width: 100vw;
  }
}
.mapInfoContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 620px) {
    flex-direction: column;
  }
}
.map {
  border-radius: 3em;
  overflow: hidden;
  width: 30em;
  height: 25em;

  @media (max-width: 620px) { 
    width: 80vw;
    height: 70vw;
    border-radius: 2vw;
    margin-bottom: 8vw;
  }
}
.contactTitle {
  font-size: 2.3em;
  font-weight: 600;
  color: white;
  margin-bottom: 2em;
  color: #f15719;

  @media (max-width: 620px) { 
    font-size: 8vw;
    margin-bottom: 9vw;
  }
}
.info {
  display: flex;
  flex-direction: column;
  margin-left: 2em;
  width: 26em;

  @media (max-width: 620px) { 
    width: 80vw;
    margin-left: 0;
    font-size: 4vw;
  }
}
.address,
.mobile,
.email {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  color: rgba(255, 255, 255, 0.87);
  padding-bottom: 2em;
  text-align: left;
}
.address {
  padding-bottom: 1.2em;
}
.addressTitle,
.mobileTitle,
.emailTitle {
  font-size: 1.2em;
  font-weight: 600;
  margin-right: 1em;
  color: #f15719;

  @media (max-width: 620px) { 
    font-size: 4.6vw;
  }
}
.closeButton {
  position: absolute;
  top: 1.6em;
  right: 1.6em;
  width: 1em;
  height: auto;
  cursor: pointer;

  @media (max-width: 620px) { 
    top: 7vw;
    right: 7vw;
    width: 3.4vw;
  }
}
</style>
