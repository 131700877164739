<template>
    <div class="ConversionsPage">
      <div class="title">Conversions</div>
      <div class="CovnversionsContainer">
        <div class="CovnversionContainer">
            <img class="conversionImage" src="../assets/main/2.jpg" alt="">
            <div class="infoContainer">
                <div class="conversionTitle">STANDARD CONVERSION</div>
                <div class="brands">Toyota Regius & Alphard, Nissan Elgrand E51</div>
                <!-- <div class="price">6,400.00£</div> -->
                <div @click.prevent="$emit('popup')" class="button">Learn more</div>
            </div>
        </div>
        <!-- <div class="CovnversionContainer">
            <img class="conversionImage" src="../assets/main/2.jpg" alt="">
            <div class="infoContainer">
                <div class="conversionTitle">STANDARD CONVERSION</div>
                <div class="brands">Toyota Regius & Alphard, Nissan Elgrand E51</div>
                <div class="price">6,400.00£</div>
                <div @click.prevent="$emit('popup')" class="button">Select</div>
            </div>
        </div> -->
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "VanGallery",
  
    components: {  },
  
    setup() {

      return {

      };
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .ConversionsPage {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    margin-left: 2em;
  }
  .title {
    display: flex;
    /* flex-direction: row; */
    justify-content: flex-start;
    width: 36em;
    font-size: 2.5em;
    font-weight: 600;
    color: #f15719;
    margin-bottom: 1.2em;

    @media (max-width: 620px) {
      font-size: 10vw;
      margin-bottom: 3vw;
    }
  }
  .CovnversionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* align-items: flex-start; */
    width: 100vw;

    @media (max-width: 620px) {
      flex-direction: column;
    }
  }
  .CovnversionContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; 
    

    @media (max-width: 620px) {
      flex-direction: column;
    }
  }
  .conversionImage {
    width: 20em;
    height: auto;
    border-radius: 1em;

    @media (max-width: 620px) {
      margin-top: 10vw;
    }
  }
  .infoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 2em;
    text-align: start;
    width: 22em;

    @media (max-width: 620px) {
      width: 80vw;
      margin-left: 0;
      font-size: 3.9vw;
      margin-top: 4vw;
    }
  }
  .conversionTitle {
    font-size: 1.4em;
    font-weight: 600;
    color: white;
    margin-bottom: 0.3em;
  }
  .brands {
    color: white;
    opacity: 0.8;
  }
  .price {
    font-size: 1.6em;
    font-weight: 600;
    color: white;
    margin-top: 1.4em;

    @media (max-width: 620px) {
      margin-top: 4vw;
    }
  }
  .button {
    color: white;
    background: linear-gradient(to right, #F18C19, #F15819);
    padding: 0.5em 1.5em;
    /* width: 8em; */
    border-radius: 0.4em;
    margin-top: 2em;
    cursor: pointer;

    @media (max-width: 620px) {
      margin-top: 3vw;
    }
  }
  .button:hover {
    background: linear-gradient(to right, #faaa4d, #ed8c63);
  }

  </style>
  