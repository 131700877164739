<template>
  <router-view></router-view>
</template>

<script>
import { useSanityClient } from 'vue-sanity'

export default {
  name: 'App',
  setup() {
    useSanityClient({
      projectId: 'gd0lm6dh',
      dataset: 'production',
      useCdn: process.env.NODE_ENV === 'production',
      apiVersion: '2021-10-21'
    })
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, OpenSans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  background-color: #0F0F16;
  height: 100vh;
  overflow: hidden;
}
body {
  margin: 0;
}
</style>



