<template>
  <div class="RentPage">
    <div class="titleInfoContainer">
      <div class="title">
        <div class="title1">For</div>
        <div class="title2">Rent</div>
      </div>
      <div class="info">
        We are sorry to say that we are fully
        <br />
        booked at the moment. Please check
        <br />
        again at a later time.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VanGallery",

  components: {},

  setup() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.RentPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  margin-top: 5em;
  margin-bottom: 5em;
  margin-left: 2em;
}
.titleInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.title {
  display: flex;
  justify-content: flex-start;
  width: 36em;
  font-size: 2.5em;
  font-weight: 600;
  margin-bottom: 0.5em;

  @media (max-width: 620px) {
    font-size: 10vw;
  }
}
.title1 {
  padding-right: 0.3em;
  color: white;
  opacity: 0.8;
}
.title2 {
  color: #f15719;
}
.info {
  font-size: 1.3em;
  font-weight: 600;
  text-align: start;

  @media (max-width: 620px) {
    font-size: 4.5vw;
  }
}
</style>
