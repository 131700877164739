import { createRouter, createWebHistory } from 'vue-router';

// Import your components for each route
import Home from './components/HomePage.vue';
import Contact from './components/Contact.vue';
// Add more components as needed

const routes = [
  { path: '/', component: Home },
  { path: '/contact', component: Contact },
  // Define more routes as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router; 