<template>
    <div class="CampervanPopup">
        <div @click.prevent="$emit('close')" class="bg"></div>
        <div class="popupWrapper">

            <img
                draggable="false"
                @click.prevent="$emit('close')"
                class="closeButton"
                src="../assets/closeButton.png"
                alt=""
            />
            <div class="imageContainerWrapper">
                <div class="imageContainer" ref="imageContainer">
                    <Viewer :images="images" :options="options" class="viewer">
                    <img
                        v-for="(img, i) in images"
                        :src="img"
                        :key="i"
                        class="image"
                    />
                    </Viewer>
                </div>
                <button v-if="slideIndex>0.5" class="prev-btn" @click="prevSlide">&lt;</button>
                <button class="next-btn" @click="nextSlide">&gt;</button>
            </div>
          
            <div class="infoContainer">
                <div class="title" > PACKAGE INCLUDES: </div>
                <div class="info">
                    * KITCHEN
                    <br> * BAR FRIDGE
                    <br> * SINK & HOB
                    <br> * WATER TANKS
                    <br> * FLOORING
                    <br> * VINYL
                    <br> * ROCK-&-ROLL BED
                    <br> * LEISURE BATTERY
                    <br> * POP TOP ROOF   
                    <br> * BED ON THE ROOF    
                    <br> * SLIDING RAIL SYSTEM FOR ROCK-&-ROLL BED   
                    <br> * CURTAINS
                    <br> * AWNING
                    <br> * SOLAR PANEL
                    <br> * ROOF RAILINGS
                    <br> * 4G PORTABLE WI-FI KIT
                    <br> * TOW BAR
                    <br> * ELECTRIC NIGHT HEATER
                    <br> * PARKING SENSOR SYSTEM
                    <br> * TV SET
                    <br> * AUDIO SYSTEM
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import "viewerjs/dist/viewer.css";
  import { component as Viewer } from "v-viewer"
  
  export default {
    name: "VanGallery",
    components: {Viewer},
    props: {},
  
    data() {
      return {
        images: [
          require("../assets/conversions/1.jpg"),
          require("../assets/conversions/2.jpg"),
          require("../assets/conversions/3.jpg"),
          require("../assets/conversions/4.jpg"),
          require("../assets/conversions/5.jpg"),
          require("../assets/conversions/6.jpg"),
          require("../assets/conversions/7.jpg"),
          require("../assets/conversions/8.jpg"),
          require("../assets/conversions/9.jpg"),
          require("../assets/conversions/10.jpg"),
          require("../assets/conversions/11.jpg"),
          require("../assets/conversions/12.jpg"),
          require("../assets/conversions/13.jpg"),
          require("../assets/conversions/14.jpg"),
          require("../assets/conversions/15.jpg"),
        ],
        options: { 
            toolbar: false,
            title: false,
            // url: 'data-source',
        },
        slideIndex: 0,
      };
    },
  
    computed: {
      isMobile() {
        if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
      },
    },

  
    methods: {
        prevSlide() {
            if(this.isMobile){
                this.slideIndex = (this.slideIndex - 1 + this.images.length % this.images.length);
            } else {    
                this.slideIndex = (this.slideIndex - 1 + (this.images.length/2) % (this.images.length/2));
            }
            this.updateSlider()
        },
        nextSlide() {
            if(this.isMobile){
                this.slideIndex = (this.slideIndex + 1) % this.images.length;
            } else {    
                this.slideIndex = (this.slideIndex + 1) % (this.images.length/2);
            }
            this.updateSlider()
        },
        updateSlider() {
            const translateX = -this.slideIndex * 100;
            this.$refs.imageContainer.style.transform = `translateX(${translateX}%)`;
        }
    },
    mounted() {
        setTimeout(() => {
            this.updateSlider();
        }, 0);
    }
  };
  </script>
  
  <style scoped>
    .CampervanPopup {
       position: relative;
    }

    .popupWrapper {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #0b1622;
        /* height: 54em; */
        max-height: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
        scrollbar-color: transparent transparent;
        width: 37.5em;
        border-radius: 1.4em;

        @media (max-width: 620px) {
            width: 100vw;
            height: 100vh;
            max-height: 100%;
            border-radius: 0;
        }
    }
    .popupWrapper::-webkit-scrollbar {
        width: 0 !important; 
        background: transparent;
    }
    .bg {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.698);
        z-index: 2;
    }

    .CampervanPopup::-webkit-scrollbar-thumb {
        background-color: transparent; /* Make the thumb (the draggable part) transparent */
    }
    .imageContainerWrapper {
        position: relative;
        width: 37.5em;
        margin-top: 4em;
        margin-bottom: 1.5em;

        @media (max-width: 620px) {
            width: 80vw;
            height: 90vw;
            margin-top: 4em;
        }
    }
    .imageContainer  {
        transition: transform 0.5s ease-in-out;
    }
    .infoContainer {
        width: 100%;
        padding-left: 6em;

        @media (max-width: 620px) {
            padding-left: 15vw;
        }
    }
    .image {
        width: auto;
        height: 13.99em;
        border: 1px solid rgb(255, 255, 255);
        cursor: pointer;

        @media (max-width: 620px) {
            width: 100%;
            height: auto;
        }
    }
    .price {
        font-size: 2.3em;
        z-index: 100;
        color: white;
        align-self: flex-start;
        margin-left: 1.2em;
        margin-top: 0.5em;

        @media (max-width: 620px) {
            margin-left: 6vw;
        }
        .empty {
            display: none;
        }
    }
    .title {
        font-size: 1.5em;
        font-weight: 600;
        color: #F15719;
        margin-top: 0.5em;
        text-align: start;

        @media (max-width: 620px) {
           font-size: 6vw;
           width: 90vw;
        }
    }
    .year {
        font-size: 0.9em;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.782);
        margin-top: 0.2em;
        text-align: start;
    }
    .conversionLink {
        font-size: 1em;
        font-weight: 500;
        color: #29ABE2;
        opacity: 0.6;
        cursor: pointer;
        margin-top: 0.8em;
        text-align: start;

        @media (max-width: 620px) {
           width: 90vw;
           font-size: 3.8vw;
        }
    }
    .info {
        font-size: 0.9em;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.782);
        text-align: start;
        width: 85%;
        margin-top: 1em;
        margin-bottom: 4em;

        @media (max-width: 620px) {

        }
        
    }
    .shrinkedInfo {
        overflow: hidden;
    }
    .buttonsRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .buyButton {
        color: white;
        background: linear-gradient(to right, #F18C19, #F15819);
        padding: 0.5em 2.4em;
        border-radius: 0.4em;
        margin-top: 2em;
        margin-bottom: 3em;
        text-decoration: none;
        cursor: pointer;
    }
    .readMoreButton {
        color: rgb(128, 158, 229);
        padding: 0.5em 2.4em;
        border-radius: 0.4em;
        margin-top: 2em;
        text-decoration: none;
        cursor: pointer;
        opacity: 0.6;
    }
    .readMoreButton:hover {
        opacity: 1;
    }
    .buyButton:hover {
        background: linear-gradient(to right, #faaa4d, #ed8c63);
    }

    .closeButton {
        position: fixed;
        top: 1.6em;
        right: 1.6em;
        width: 1em;
        height: auto;
        cursor: pointer;
        z-index: 100;
    }
    .viewer {
        display: flex;
        width: 100%;
    }
    .prev-btn,
    .next-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        font-size: 24px;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        outline: none;

        @media (max-width: 620px) {

        }
    }

    .prev-btn {
        left: 0;
        
        @media (max-width: 620px) {
            left: -10vw;
        }
    }

    .next-btn {
        right: 0;

        @media (max-width: 620px) {
            right: -10vw;
        }
    }
  </style>
  