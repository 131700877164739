<template>
    <div class="Header">
      <img :draggable="false" class="logo" src="../assets/logo.svg" alt="">
      <router-link to="/" @click="setActiveSection('home')" class="button">Home</router-link>
      <div @click="setActiveSection('sales')" class="button">Sales</div>
      <div @click="setActiveSection('soon')" class="button">Coming Soon</div>
      <div @click="setActiveSection('conversions')" class="button">Conversions</div>
      <div @click="setActiveSection('rentals')" class="button">Rentals</div>
      <div @click="$emit('showContact')" class="button">Contact</div>
      <!-- <router-view></router-view> -->
    </div>
  </template>
  
  <script>

  export default {
    name: 'HomePage',
    props: {
      activeSection: String
    },
    setup(props, { emit }) {
      
      const setActiveSection = (pageName) => {
        emit('setPage', pageName)
      };

      return {
        setActiveSection
      }
    },
  
  }

  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .Header {
      position: fixed;
      display: flex;
      align-items: center;
      top: 0;
      padding-top: 2em;
      padding-bottom: 2em;
      padding-left: 4em;
      width: 100vw;
      display: flex;
      flex-direction: row;
      z-index: 2;
      /* background-color: rgba(0, 0, 0, 0.362); */

      @media (max-width: 620px) {
          width: 80vw;
          height: 80vw;
          display: none;
      }
    }
    .button {
      font-size: 1.1em;
      color: white;
      padding: 0.5em 1em;
      opacity: 0.5;
      text-decoration: none;
      cursor: pointer;

    }
    .button.active {
      opacity: 1;
    } 
    .button:hover {
      opacity: 1;
    }

    .logo {
      width: 6em;
      height: auto;
      padding-right: 4em;
      opacity: 0.8;
    }
  </style>
  