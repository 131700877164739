<template>
  <div class="HomePage">
    <div ref="home" class="mainPage">
      <div class="homeBgContainer">
        <img class="homeBgImage" src="../assets/homeBG.png" alt="">
      </div>
      
      <div class="mainPageContainer">
        <div class="teaserContainer">
          <div class="titleContainer">
            <div class="title1">Ideal</div>
            <div class="title2">Campervan</div>
          </div>
          <div class="teaser">
            A family run business who believe in providing 
            <br>
            honest friendly advise and services to meet 
            <br>
            your value for money requirements.
            <br>
            <div class="spacer"></div>
            Attention to detail is our specialty.
          </div>
          <div @click="showContact=true" class="teaserButton">Contact us</div>
        </div>
        <MainPageImageSlider class="mainImageSlider" />
      </div>
    </div>

    <div ref="sales">
      <CampervanGallery @contact="showContact=true" @show-popup="showCampervan" />
    </div>

    <div ref="soon">
      <ComingSoonGallery @contact="showContact=true" @show-popup="showCampervan"/>
    </div>
    
    <div ref="conversions">
      <ConversionsPage @popup="showConversionPopup=true"/>
    </div>

    <div ref="rentals">
      <RentPage />
    </div>

    <Header :activeSection="activeSection" @setPage="setSection" @showContact="showContact=true" :class="{ 'header-bg': isHeaderBackgroundVisible }" />

    <ContactPopup @close="showContact=false" v-if="showContact"/>

    <CampervanPopup v-if="showCampervanPopup" @close="showCampervanPopup=false" @contact="showContact=true, showCampervanPopup=false" :campervanInfo="campervanInfo"/>

    <ConversionPopup v-if="showConversionPopup" @close="showConversionPopup=false" />

   </div>
</template>

<script>
import Header from './Header.vue'
import MainPageImageSlider from './MainPageImageSlider.vue'
import { ref } from 'vue';
import CampervanGallery from './CampervanGallery.vue'
import ComingSoonGallery from './ComingSoonGallery.vue'
import ConversionsPage from './ConversionsPage.vue'
import RentPage from './RentPage.vue'
import ContactPopup from './Contact.vue'
import CampervanPopup from './CampervanPopup.vue'
import ConversionPopup from './ConversionPopup.vue'

export default {
  name: 'HomePage',
  props: {},
  components: {Header, MainPageImageSlider, CampervanGallery, ConversionsPage, RentPage, ContactPopup, ComingSoonGallery, CampervanPopup, ConversionPopup},
  data() {
    return {
      campervanInfo: {forSale: true, campervanIndex: 0},
      activeSection: 'home',
      isHeaderBackgroundVisible: false,
    }
  },
  
  setup() {
    const showContact = ref(false)
    const showCampervanPopup = ref(false)
    const showConversionPopup = ref(false)

    return {
      showContact,
      showCampervanPopup,
      showConversionPopup
    }
  },
  watch: {
    activeSection(p) {
      switch (p) {
        case 'home':
        case 'sales':
        case 'soon':
        case 'conversions':
        case 'rentals':
        case 'contact':
          this.scrollToSection(p); // Use `this` to access the component's method
          break;
        default:
          break;
      }
    }
  },  
  mounted(){
    // window.addEventListener('scroll', this.handleScroll);
    document.querySelector('.HomePage').addEventListener('scroll', this.handleScroll);
  },
  methods: {
    showCampervan(i){
      this.showCampervanPopup=true
      this.campervanInfo.campervanIndex = i.campervanIndex
      this.campervanInfo.forSale = i.isSale
    },
    setSection (dataFromChild) {
      this.activeSection = dataFromChild
      setTimeout(() => {
        this.activeSection = ''
      }, 10);
    },
    navigateToSection(section) {
      const target = this.$refs(section)
      target.scrollIntoView({ behavior: 'smooth' })
    },
    scrollToSection(refName) {
      this.$nextTick(() => {
        const target = this.$refs[refName];
        if (target && typeof target.scrollIntoView === 'function') {
          target.scrollIntoView({ behavior: 'smooth' });
        } else {
          console.error(`Element with ref '${refName}' not found or does not support scrollIntoView.`);
        }
      });
    },
    handleScroll() {
      console.log('Handling scroll event...');
      const scrollPosition = document.querySelector('.HomePage').scrollTop
      const threshold = 100; 

      if (scrollPosition > threshold) {
        this.isHeaderBackgroundVisible = true;
      } else {
        this.isHeaderBackgroundVisible = false;
      }
    },
  },
  
}
</script>

<style scoped>
  .HomePage {
    position: relative;
    height: 100vh; 
    overflow-y: auto; 
    overflow-x: hidden;
    scrollbar-width: 0;
    scrollbar-color: transparent transparent;

    @media (max-width: 620px) {
    }
  }
  .HomePage::-webkit-scrollbar {
      width: 0 !important; 
      background: transparent;
  }
  .mainPage {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .homeBgContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 50em;
    width: 100vw;
    overflow: hidden;
  }
  .homeBgImage {
    width: 100vw;
    min-width: 100em;
    max-width: 100vw;
    height: auto;    
  }
  .mainPageContainer {
    width: 70em;
    height: 50em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;

    @media (max-width: 620px) {
      flex-direction: column;
      width: 100vw;
    }
  }
  .mainImageSlider {
    width: 20em;
    height: 20em;
    z-index: 100000;
    margin-left: 7em;
    order: 1;

    @media (max-width: 620px) {
      margin-left: 0;
      margin-top: 1em;
      order: 0;
      width: 80vw;
      height: auto;
    }
    @media only screen and (min-width: 600px) and (max-width: 1200px) {
      margin-left: 2em;
    }
  }
  .teaserContainer {
    order: 0;
    @media (max-width: 620px) {
      order: 1;
      margin-top: 2.4em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .titleContainer {
    display: flex;
    flex-direction: row;
    font-size: 3em;
    font-weight: 600;

    @media (max-width: 620px) {
      font-size: 10vw;
    }
  }
  .title1 {
    padding-right: 0.3em;
    color: white;
    opacity: 0.8;
  }    
  .title2 {
    color: #F15719;
  }
  .teaser {
    color: white;
    text-align: start;
    margin-top: 1.3em;
    font-size: 1em;

    @media (max-width: 620px) {
      text-align: center;
      font-size: 3.7vw;
    }
  }
  .teaserButton {
    color: white;
    background: linear-gradient(to right, #F18C19, #F15819);
    padding: 0.4em 0.2em;
    width: 8em;
    border-radius: 0.4em;
    margin-top: 2em;
    cursor: pointer;

    @media (max-width: 620px) {
      font-size: 4vw;
    }
  }
  .teaserButton:hover {
    background: linear-gradient(to right, #faaa4d, #ed8c63);
  }
  .spacer {
    height: 0.7em;
  }

  .HomePage::-webkit-scrollbar {
  width: 0.5rem; 
}
.HomePage::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); 
}
.HomePage::-webkit-scrollbar-track {
  background-color: transparent; 
}
.HomePage {
  scrollbar-width: none;
}

.header-bg {
  background-color: rgba(0, 0, 0, 0.8); 
  transition: background-color 0.3s ease; 
}
</style>
