<template>
  <div class="VanGallery">
    <div class="titleContainer">
      <div class="title">
        <div class="title1">For</div>
        <div class="title2">Sale</div>
      </div>
    </div>
    <flicking
      ref="flick"
      :key="3"
      :options="{ circular: false, panelsPerView: -1, align: 'prev',  }"
      :plugins="plugins"
    >
      <div class="spacer"></div>
      <div v-for="(campervan, index) in allCampervans" :key="index" class="campervan">
        <Campervan 
        @contact="$emit('contact')"
        @show-popup="showPopup"
        :index="index"
        :isSale="true"
        :isEmpty="campervan.isEmpty"
        :image="campervan.image" 
        :price="campervan.price" 
        :title="campervan.title" 
        :year="campervan.year"
        :conversion-link="campervan.conversionLink"
        :info="campervan.info"
        :sold="campervan.isSold"/>
      </div>

      <template #viewport>
        <div class="flicking-pagination"></div>
      </template>
    </flicking>
  </div>
</template>

<script>
import Campervan from "./Campervan.vue";
import Flicking from "@egjs/vue3-flicking";
import "@egjs/vue3-flicking/dist/flicking-inline.css";
import { Fade } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
// import { campervans } from "../campervans";
import { useSanityFetcher } from 'vue-sanity'

export default {
  name: "VanGallery",

  components: { Campervan, Flicking },

  mounted(){

  },
  computed: {
    allCampervans(){
      const campervans = this.campervansRaw?.map(van => {
        return {
          image: van.frontImage,
          price: van.price,
          year: van.yearMiles,
          title: van.name,
          conversionLink: "Standard Conversion (see Standard Conversion page)",
          info: van.description,
          isEmpty: false,
          isSold: van.sold
        }
      }) ?? []

      if(this.campervansRaw && this.campervansRaw.length > 5){
        return campervans
      } else {
        const emptyCampervansCount = 6 - campervans.length
        let campervansWithEmpty = campervans
        for (let i = 0; i < emptyCampervansCount; i++) {
          const emptyCampervan = {isEmpty: true}
          campervansWithEmpty.push(emptyCampervan)
        }
        return campervansWithEmpty
      }
      
    }
  },
  methods:{
    showPopup(i){
      this.$emit('showPopup', i)
    },
  },  

  setup() {
    const plugins = [new Fade()];
    const { data: campervansRaw } = useSanityFetcher(`*[_type == "forSale"]{
      name,
      "frontImage": frontImage.asset->url,
      price,
      yearMiles,
      description,
      sold
    }`)
    return {
      plugins,
      campervansRaw
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.VanGallery {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  margin-top: 3em;
  margin-bottom: 5em;
  margin-left: 1em;
}
.campervan {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  width: 18em;
  /* border: 1px solid red; */
  padding: 2em 1em;

  @media (max-width: 620px) {
    width: 70vw;
    /* padding: 2em 1em; */
  }
}
.emptyContainer {
  width: 18em;
  padding: 2em 1em;
  background-color: #f15719;

  @media (max-width: 620px) {
    width: 80vw;
  }
}
.titleContainer {
  display: flex;
  flex-direction: row;
  font-size: 2.5em;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 0.2em;
}
.title {
  display: flex;
  flex-direction: row;
  width: 36em;
  margin-left: 0.5em;

  @media (max-width: 620px) {
    font-size: 10vw;
  }
}
.title1 {
  padding-right: 0.3em;
  color: white;
  opacity: 0.8;
}
.title2 {
  color: #f15719;
}
</style>
